import React, { useEffect } from "react";

import Breadcrump from "../Breadcrumb/Breadcrump";

function Page(props) {
  const { title } = props;

  useEffect(() => {
    document.title = `${title} | AJW`;
    window.scrollTo(0, 0);
  }, [title]);

  let caption;
  let menu;

  if (props.isDashboard) {
    // caption = (
    //   <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    //     <h1 className="text-2xl font-semibold text-gray-900">Guten Tag, {"<Benutzername>"}</h1>
    //   </div>
    // );
    caption = "";
  }

  if (props.caption) {
    caption = (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-4 sm:pt-4 sm:pb-0 z-10">
        <Breadcrump pages={props.pages} isBackBtn={props.isBackBtn} />
      </div>
    );
  }

  if (props.menu) {
    menu = (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 sticky top-0 pb-4 sm:pt-4 bg-cleangray-50 z-10">
        <props.menu />
      </div>
    );
  }

  return (
    <>
      {props.isLogin ? (
        props.children
      ) : (
        <>
          {caption}
          {menu}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-4 z-0">{props.children}</div>
        </>
      )}
    </>
  );
}

export default Page;
