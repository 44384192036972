import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

function PageButton({ page, isActive, handleClick }) {
  let style = "bg-white border-cleangray-150 text-cleangray-400 hover:bg-cleangray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer";

  if (isActive) {
    style = "bg-primary-base border-cleangray-150 text-white relative inline-flex items-center px-4 py-2 text-sm font-medium cursor-pointer";
  }

  return (
    <div key={uuid()} aria-current={isActive ? "page" : "false"} className={style} onClick={() => handleClick(page)}>
      {page}
    </div>
  );
}

function PageCropper(props) {
  return (
    <span key={uuid()} className="relative inline-flex items-center px-4 py-2 border border-cleangray-150 bg-white text-sm font-medium text-cleangray-600">
      ...
    </span>
  );
}

export default function Pagination({ entity, onNext, onPrev, total, limit, page, goToPage, maxPage }) {
  const [pages, setPages] = useState([]);

  function handleGoToPage(page) {
    goToPage(page);
  }

  useEffect(() => {
    setPages([]);

    if (page && maxPage) {
      if (maxPage < 7) {
        const tmp = [];
        for (let i = 0; i < maxPage; i++) {
          tmp.push(i + 1);
        }

        tmp.map(p => {
          setPages(s => [...s, <PageButton key={uuid()} page={p} isActive={p === page} handleClick={handleGoToPage} />]);
        });
      } else {
        if (page === 1 || page === 2 || page === 3) {
          [1, 2, 3].map(p => {
            setPages(s => [...s, <PageButton key={uuid()} page={p} isActive={p === page} handleClick={handleGoToPage} />]);
          });

          setPages(s => [...s, <PageCropper key={uuid()} />]);

          [maxPage - 2, maxPage - 1, maxPage].map(p => {
            setPages(s => [...s, <PageButton key={uuid()} page={p} isActive={p === page} handleClick={handleGoToPage} />]);
          });
        }

        if (page >= 4 && page <= maxPage - 3) {
          [1].map(p => {
            setPages(s => [...s, <PageButton key={uuid()} page={p} isActive={p === page} handleClick={handleGoToPage} />]);
          });

          setPages(s => [...s, <PageCropper key={uuid()} />]);

          [page - 1, page, page + 1].map(p => {
            setPages(s => [...s, <PageButton key={uuid()} page={p} isActive={p === page} handleClick={handleGoToPage} />]);
          });

          setPages(s => [...s, <PageCropper key={uuid()} />]);

          [maxPage].map(p => {
            setPages(s => [...s, <PageButton key={uuid()} page={p} isActive={p === page} handleClick={handleGoToPage} />]);
          });
        }

        if (page === maxPage || page === maxPage - 1 || page === maxPage - 2) {
          [1, 2, 3].map(p => {
            setPages(s => [...s, <PageButton key={uuid()} page={p} isActive={p === page} handleClick={handleGoToPage} />]);
          });

          setPages(s => [...s, <PageCropper key={uuid()} />]);

          [maxPage - 2, maxPage - 1, maxPage].map(p => {
            setPages(s => [...s, <PageButton key={uuid()} page={p} isActive={p === page} handleClick={handleGoToPage} />]);
          });
        }
      }
    }
  }, [page, maxPage]);

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between sm:px-6 shadow overflow-hidden rounded-md mt-4">
      <div className="flex-1 flex justify-between sm:hidden">
        <div onClick={onPrev} className="relative inline-flex items-center px-4 py-2 border border-cleangray-150 text-sm font-medium rounded-md text-cleangray-600 bg-white hover:bg-cleangray-50">
          Zurück
        </div>
        <div onClick={onNext} className="ml-3 relative inline-flex items-center px-4 py-2 border border-cleangray-150 text-sm font-medium rounded-md text-cleangray-600 bg-white hover:bg-cleangray-50">
          Weiter
        </div>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-cleangray-600">
            {entity} <span className="font-medium">{total > 0 ? (page === 1 ? "1" : (page - 1) * limit) : "0"}</span> bis <span className="font-medium">{page === 1 ? (total < limit ? total : limit) : page === parseInt(total / limit) + 1 ? (page - 1) * limit + (total % limit) : page * limit}</span> von <span className="font-medium">{total}</span>
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button disabled={page === 1} className={"relative inline-flex items-center px-2 py-2 rounded-l-md border border-cleangray-150 bg-white text-sm font-medium text-cleangray-400 hover:bg-cleangray-50 cursor-pointer disabled:opacity-50"} onClick={onPrev}>
              <span className="sr-only">Zurück</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {pages}

            <button disabled={page === parseInt(total / limit) + 1} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-cleangray-150 bg-white text-sm font-medium text-cleangray-400 hover:bg-cleangray-50 cursor-pointer disabled:opacity-50" onClick={onNext}>
              <span className="sr-only">Weiter</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
