/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChartBarIcon, CubeIcon, BriefcaseIcon, HomeIcon, MenuIcon, XIcon, LogoutIcon, ClipboardListIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import CustomNavLink from "../CustomNavLink/CustomNavLink";
import { useSelector, useDispatch } from "react-redux";

// Store
import * as authActions from "../../store/actions/auth";
import * as uiActions from "../../store/actions/ui";

import Logo from "../../assets/ajw-logo-light.svg";

const navigation = [
  { name: "Dashboard", href: "/dashboard", icon: HomeIcon, current: false },
  { name: "Aufträge", href: "/jobs", icon: ClipboardListIcon, current: false },
  { name: "Modelle", href: "/products", icon: CubeIcon, current: false },
  { name: "Kunden", href: "/clients", icon: BriefcaseIcon, current: false }
  // { name: "Berichte", href: "/reports", icon: ChartBarIcon, current: false }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  function handleLogout() {
    dispatch(authActions.logout());
    dispatch(uiActions.flashMessage("success", "Abmeldung erfolgreich"));
  }

  const imgPlaceholder = (
    <div className="text-white bg-primary-base h-10 w-10 rounded-full flex justify-center items-center">
      <p className="text-sm">
        {user.firstname[0].toUpperCase()}
        {user.lastname[0].toUpperCase()}
        {user.lastname.split("-")[1][0].toUpperCase()}
      </p>
    </div>
  );

  return (
    <div className="h-screen flex overflow-hidden bg-cleangray-50">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-cleangray-650 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full">
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-cleangray-650">
              <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button" className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" onClick={() => setSidebarOpen(false)}>
                    <span className="sr-only">Menü schließen</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>

              {/* Sidebar for mobile */}
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex justify-center items-center px-4 py-4">
                  <img className="h-14 w-14" src={Logo} alt="AJW" />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map(item => (
                    <CustomNavLink onClick={() => setSidebarOpen(false)} key={item.name} to={item.href} activeClassName="bg-cleangray-700 text-white group flex items-center px-2 py-2 text-base font-medium rounded-md" inactiveClassName="text-cleangray-300 hover:bg-cleangray-600 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md">
                      <item.icon className={classNames(item.current ? "text-cleangray-300" : "text-cleangray-400 group-hover:text-cleangray-300", "mr-4 flex-shrink-0 h-6 w-6")} aria-hidden="true" />
                      {item.name}
                    </CustomNavLink>
                  ))}
                </nav>
              </div>
              <div className="flex-shrink-0 flex justify-between">
                <Link to={"/profile"} className="w-full" onClick={() => setSidebarOpen(false)}>
                  <div className="w-full flex bg-cleangray-600 p-4">
                    <div className="flex-shrink-0 group block">
                      <div className="flex items-center">
                        <div>{user.imgUrl ? <img className="inline-block h-10 w-10 rounded-full" src={user.imgUrl} alt="User" /> : imgPlaceholder}</div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-white">{user.firstname + " " + user.lastname}</p>
                          <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">Zum Profil</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="flex-shrink-0 flex justify-center items-center bg-cleangray-600 p-4 cursor-pointer group" onClick={handleLogout}>
                  <LogoutIcon className="text-cleangray-400 w-6 h-6 group-hover:text-cleangray-300" />
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-cleangray-650">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex justify-center items-center flex-shrink-0 px-4 py-4">
                <img className="h-14 w-14" src={Logo} alt="AJW" />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-cleangray-650 space-y-1">
                {navigation.map(item => (
                  <CustomNavLink key={item.name} to={item.href} activeClassName={classNames("bg-cleangray-700 text-white", "group flex items-center px-2 py-2 text-sm font-medium rounded-md")} inactiveClassName={classNames("text-cleangray-300 hover:bg-cleangray-600 hover:text-white", "group flex items-center px-2 py-2 text-sm font-medium rounded-md")}>
                    <item.icon className={classNames(item.current ? "text-cleangray-300" : "text-cleangray-400 group-hover:text-cleangray-300", "mr-3 flex-shrink-0 h-6 w-6")} aria-hidden="true" />
                    {item.name}
                  </CustomNavLink>
                ))}
              </nav>
            </div>
            <div className="flex-shrink-0 flex justify-between">
              <Link to={"/profile"} className="w-full" onClick={() => setSidebarOpen(false)}>
                <div className="flex bg-cleangray-600 p-4 pr-0 w-full">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <div>{user.imgUrl ? <img className="inline-block h-9 w-9 rounded-full" src={user.imgUrl} alt="User" /> : imgPlaceholder}</div>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-white">
                        {user.firstname} {user.lastname}
                      </p>
                      <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">Zum Profil</p>
                    </div>
                  </div>
                </div>
              </Link>
              <div className="flex-shrink-0 flex justify-center items-center bg-cleangray-600 p-4 cursor-pointer group" onClick={handleLogout}>
                <LogoutIcon className="text-cleangray-400 w-6 h-6 group-hover:text-cleangray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button type="button" className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-cleangray-400 hover:text-cleangray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cleangray-400" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Menü öffnen</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div>{props.children}</div>
        </main>
      </div>
    </div>
  );
}
