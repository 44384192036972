import { CubeIcon, ClockIcon, BriefcaseIcon } from "@heroicons/react/solid";

export default function JobList(props) {
  const jobs = props.data;

  let list;

  if (jobs.length > 0) {
    list = jobs.map((job) => (
      <li key={job.id}>
        <a href="#" className="block hover:bg-cleangray-50">
          <div className="px-4 py-4 sm:px-6">
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-primary-base truncate">{job.title}</p>
              <div className="ml-2 flex-shrink-0 flex">
                <p className={"px-2 inline-flex text-xs leading-5 font-semibold rounded-full " + (job.type === "Eingegangen" ? "bg-red-100 text-red-800" : job.type === "In Bearbeitung" ? "bg-yellow-100 text-yellow-600" : "bg-green-100 text-green-800")}>{job.type}</p>
              </div>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
              <div className="sm:flex">
                <p className="flex items-center text-sm text-cleangray-400">
                  <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-cleangray-300" aria-hidden="true" />
                  {job.client}
                </p>
                <p className="mt-2 flex items-center text-sm text-cleangray-400 sm:mt-0 sm:ml-6">
                  <CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-cleangray-300" aria-hidden="true" />
                  {job.model}
                </p>
              </div>
              <div className="mt-2 flex items-center text-sm text-cleangray-400 sm:mt-0">
                <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-cleangray-300" aria-hidden="true" />
                <p>
                  Deadline in <time dateTime={job.closeDate}>{job.closeDateFull}</time> {job.closeDateFull === "1" ? "Tag" : "Tagen"}
                </p>
              </div>
            </div>
          </div>
        </a>
      </li>
    ));
  } else {
    list = (
      <li key="0" className="bg-white shadow overflow-hidden rounded-md px-6 py-4">
        <p className="text-sm font-medium text-cleangray-400 truncate text-center">Keine laufenden Aufträge</p>
      </li>
    );
  }

  return (
    <>
      <div className={"bg-white shadow overflow-hidden rounded-md " + props.className}>
        <ul className="divide-y divide-cleangray-150">{list}</ul>
      </div>
    </>
  );
}
