import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function GridItem({ product }) {
  const [error, setError] = useState(false);

  return (
    <li key={product._id} className="relative">
      <Link to={`/products/${product._id}`}>
        <div className="group block w-full aspect-w-10 aspect-h-7 rounded-md bg-cleangray-150 focus-within:ring-offset-cleangray-150 overflow-hidden">
          <img src={axios.defaults.baseURL + (error ? "/images/error.jpg" : product.imgUrl)} alt={product.name} className="object-cover pointer-events-none group-hover:opacity-75" onError={() => setError(true)} />
          <div className="absolute inset-0 focus:outline-none">
            <span className="sr-only">{product.name}</span>
          </div>
        </div>
        <p className="mt-2 p-2 rounded-md text-center block text-sm font-medium text-cleangray-600 truncate pointer-events-none bg-cleangray-100">{product.name}</p>
      </Link>
    </li>
  );
}

export default function ModelListGrid(props) {
  const { products } = props;

  return (
    <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {products.map(product => (
        <GridItem key={product._id} product={product} />
      ))}
    </ul>
  );
}
