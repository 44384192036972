import { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, ExclamationIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";

function Message(props) {
  const [show, setShow] = useState(true);
  const { message } = props;

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 4000);
  });

  let icon;

  switch (message.type) {
    case "alert":
      icon = <ExclamationCircleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />;
      break;
    case "warning":
      icon = <ExclamationIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />;
      break;
    case "success":
      icon = <CheckCircleIcon className="h-6 w-6 text-green-500" aria-hidden="true" />;
      break;
    case "info":
      icon = <InformationCircleIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />;
      break;
  }

  return (
    <Transition show={show} as={Fragment} enter="transform ease-out duration-300 transition" enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enterTo="translate-y-0 opacity-100 sm:translate-x-0" leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
      <div className="max-w-sm w-full bg-white shadow-lg rounded-md pointer-events-auto ring-1 ring-cleangray-400 ring-opacity-25 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">{icon}</div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-cleangray-600">{message.title}</p>
              {message.subline && <p className="mt-1 text-sm text-cleangray-400">{message.subline}</p>}
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-cleangray-300 hover:text-cleangray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-base"
                onClick={() => {
                  setShow(false);
                }}>
                <span className="sr-only">Schließen</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

// Recive messgage via props
// message: {
//   title: string,
//   subline: string,
//   type: "alert" | "warning" | "success" | "info"
// }
export default function FlashMessage(props) {
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6">
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {props.messages.map((message, index) => (
            <Message key={index} message={message} />
          ))}
        </div>
      </div>
    </>
  );
}
