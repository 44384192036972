export const FLASHMESSAGE = "FLASHMESSAGE";

export function flashMessage(type, title, subline = "") {
  return {
    type: FLASHMESSAGE,
    msg: {
      type,
      title,
      subline
    }
  };
}
