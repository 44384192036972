import React, { useEffect, useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";

import LoadingCircle from "../Icons/LodaingCircle";

const SYMBOL = "+";

function TableSearchbar(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [stripedSearchTerm, setStripedSearchTerm] = useState("");
  const [mode, setMode] = useState("");

  useEffect(() => {
    if (stripedSearchTerm.trim()) {
      props.startAnimation();
      const delay = setTimeout(() => {
        props.search(stripedSearchTerm, mode);
      }, 250);

      return () => {
        clearTimeout(delay);
      };
    } else {
      props.stopAnimation();
    }
  }, [stripedSearchTerm, mode]);

  useEffect(() => {
    const s = searchTerm.trim();
    if (s.includes(SYMBOL)) {
      if (s.indexOf(SYMBOL) + s.lastIndexOf(SYMBOL) === s.lastIndexOf(SYMBOL) && s.lastIndexOf(SYMBOL) != 0) {
        setMode("middle");
      } else if (s.indexOf(SYMBOL) === 0) {
        setMode("end");
      } else if (s.lastIndexOf(SYMBOL) > 0) {
        setMode("start");
      }
    } else {
      setMode("exact");
    }
    setStripedSearchTerm(s.replaceAll(SYMBOL, ""));
  }, [searchTerm]);

  function handleChange(e) {
    setSearchTerm(e.target.value);
  }

  function handleBlur(e) {
    if (!e.target.value.trim()) {
      setSearchTerm("");
      props.search(e.target.value.trim(), "start");
    }
  }

  return (
    <div className={props.className}>
      <label htmlFor="search" className="text-sm font-medium text-cleangray-600 hidden">
        {props.placeholder}
      </label>
      <div className="relative rounded-md shadow-sm">
        <input type="text" name="search" id="search" className="focus:ring-primary-base focus:border-primary-base block w-full pr-10 sm:text-sm border-cleangray-150 rounded-md" placeholder={props.placeholder + " ..."} value={searchTerm} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">{props.isLoading ? <LoadingCircle className="h-5 w-5 animate-spin" /> : <SearchIcon className="h-5 w-5 text-cleangray-300" aria-hidden="true" />}</div>
      </div>
    </div>
  );
}

export default TableSearchbar;
