import React, { useEffect, useState } from "react";
// import { Switch, Route, Redirect } from "react-router-dom";
import { PlusIcon, ViewListIcon, ViewGridIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import axios from "axios";

// Components
import Page from "../../components/Page/Page";
import Pagination from "../../components/Pagination/Pagination";
import TableSearchbar from "../../components/TableSearchbar/TableSearchbar";
import IconButton from "../../components/IconButton/IconButton";
import ModelListTable from "../../components/ModelList/ModelListTable";
import ModelListGrid from "../../components/ModelList/ModelListGrid";

const pages = [{ name: "Modelle", href: "/products", current: true }]; // Breadcrump config

function PageMenu(props) {
  return (
    <div>
      <div className="flex space-x-3 mb-4 items-stretch justify-end">
        <IconButton className="w-12" tranparent icon={props.icon} onClick={props.onClick} />
        <TableSearchbar placeholder="Modelle durchsuchen" className="sm:w-60 w-full" search={props.onSearch} isLoading={props.isLoading} startAnimation={props.startAnimation} stopAnimation={props.stopAnimation} />
        <IconButton className="w-12" icon={PlusIcon} onClick={() => {}} />
      </div>
      {!props.gridView ? (
        <div className="hidden sm:block shadow rounded-md overflow-hidden mb-4">
          <div className="flex justify-between">
            <p className="w-full px-6 py-3 bg-white text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Modell</p>
            <p className="w-full px-6 py-3 bg-white text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Abmessung</p>
            <p className="w-full hidden px-6 py-3 bg-white text-center text-xs font-medium text-gray-500 uppercase tracking-wider md:block">Bedarf</p>
            <p className="w-full px-6 py-3 bg-white text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Status</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

function Products(props) {
  const [gridView, setGridView] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [totalProducts, setTotalProducts] = useState();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  // SearchState
  const [isSearching, setIsSearching] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchMode, setSeachMode] = useState("start");

  const userToken = useSelector(state => state.auth.user.token);

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.post(`/productSearch?searchTerm=${searchTerm}&skip=${page === 1 ? page - 1 : (page - 1) * limit}&limit=${limit}&mode=${searchMode}`, { token: userToken }, { cancelToken: fetchRequest.token });
        setProducts(response.data.results);
        setTotalProducts(response.data.totalCount);
        setIsSearching(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, [searchTerm, searchMode, page, limit]);

  function handleSearchForm(s, mode) {
    setSeachMode(mode);
    setSearchTerm(s);
  }

  function startSearchAnimation() {
    setIsSearching(true);
  }
  function stopSearchAnimation() {
    setIsSearching(false);
  }

  function goToPage(page) {
    setPage(page);
  }

  function nextPage() {
    if (page >= parseInt(totalProducts / limit) + 1) return;
    setPage(s => s + 1);
  }

  function prevPage() {
    if (page <= 1) return;
    setPage(s => s - 1);
  }

  function handleViewSwitch() {
    setGridView(s => !s);
  }

  return (
    <Page title="Modelle" caption="Modelle" pages={pages}>
      <PageMenu onSearch={handleSearchForm} isLoading={isSearching} startAnimation={startSearchAnimation} stopAnimation={stopSearchAnimation} onClick={handleViewSwitch} gridView={gridView} icon={gridView ? ViewListIcon : ViewGridIcon} />
      {products.length > 0 ? gridView ? <ModelListGrid products={products} /> : <ModelListTable products={products} /> : <p className="text-cleangray-600 text-center">Kein Modell gefunden</p>}
      <Pagination entity="Modell" goToPage={goToPage} onNext={nextPage} onPrev={prevPage} total={totalProducts} limit={limit} page={page} maxPage={parseInt(totalProducts / limit) + 1} />
    </Page>
  );
}

export default Products;
