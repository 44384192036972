import React, { useEffect } from "react";

// Components
import Page from "../../components/Page/Page";
import JobList from "../../components/JobList/JobList";

const stats = [
  { name: "Modelle", stat: "343" },
  { name: "Bestseller", stat: "1804" },
  { name: "Bester Kunde", stat: "Egon Löh" }
];

const jobs = [
  {
    id: 1,
    title: "Pressungen",
    type: "In Bearbeitung",
    model: "1502",
    client: "Egon Löh",
    closeDate: "2020-01-07",
    closeDateFull: "1"
  },
  {
    id: 2,
    title: "Pressungen",
    type: "In Bearbeitung",
    model: "1804",
    client: "Bernhard Konradt",
    closeDate: "2020-01-07",
    closeDateFull: "4"
  },
  {
    id: 3,
    title: "Pressungen",
    type: "Eingegangen",
    model: "GH01",
    client: "Golden Head",
    closeDate: "2020-01-14",
    closeDateFull: "10"
  }
];

function Stats() {
  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map(item => (
          <div key={item.name} className="px-4 py-5 bg-white shadow rounded-md overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-cleangray-400 truncate">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold text-cleangray-700">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

function CurrentJobList(props) {
  return (
    <div className="mt-5">
      <h3 className="col-span-3 ml-6 mb-5 text-lg leading-6 font-medium text-cleangray-700">Aktuelle Aufträge</h3>
      <JobList className="col-span-3" data={jobs} />
    </div>
  );
}

// function Panel(props) {
//   return (
//     <div className={"bg-white overflow-hidden shadow rounded-lg " + props.className}>
//       <div className="px-4 py-5 sm:p-6 h-96 max-w-6xl">{props.children}</div>
//     </div>
//   );
// }

function Dashboard(props) {
  return (
    <Page title="Dashboard" isDashboard>
      <Stats />
      <CurrentJobList />
    </Page>
  );
}

export default Dashboard;
