import { FLASHMESSAGE } from "../actions/ui";

const initialState = {
  flashMessages: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FLASHMESSAGE:
      return {
        ...state,
        flashMessages: [...state.flashMessages, action.msg]
      };
  }

  return state;
};
