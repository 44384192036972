import React from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon, CheckCircleIcon, QuestionMarkCircleIcon, ExclamationCircleIcon, ExclamationIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ModelListTable(props) {
  const { products } = props;

  function getStatusIcon(status) {
    switch (status) {
      // Okay
      case "0":
        return <CheckCircleIcon className="flex-shrink-0 h-5 w-5 text-green-500" />;

      // Schneidewerkzeug defekt
      case "1":
        return <ExclamationIcon className="flex-shrink-0 h-5 w-5 text-yellow-500" />;

      // Prägewerkzeug defekt
      case "2":
        return <ExclamationCircleIcon className="flex-shrink-0 h-5 w-5 text-red-500" />;

      // Status nicht bekannt
      case "-1":
        return <QuestionMarkCircleIcon className="flex-shrink-0 h-5 w-5 text-blue-500" />;

      default:
        return <span>No Icon</span>;
    }
  }

  return (
    <>
      {/* Activity list (smallest breakpoint only) */}
      <div className="shadow sm:hidden rounded-md overflow-hidden">
        <ul role="list" className="divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
          {products.map(product => (
            <li key={product._id}>
              <Link to={`/products/${product._id}`} className="block px-4 py-4 bg-white hover:bg-gray-50">
                <span className="flex items-center space-x-4">
                  <span className="flex-1 flex justify-center space-x-2 truncate">
                    <span className="flex space-x-10 text-gray-500 text-sm truncate">
                      <span className="truncate">{product.name}</span>
                      {/* <span className="truncate text-cleangray-300">
                        {(parseFloat(product.length) / 100).toFixed().toString().replace(".", ",")}x{(parseFloat(product.thickness) / 100).toFixed(2).toString().replace(".", ",")}
                      </span> */}
                    </span>
                  </span>
                  <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      {/* Activity table (small breakpoint and up) */}
      <div className="hidden sm:block">
        <div className="flex flex-col">
          <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-md">
            <div className="hidden sm:block shadow rounded-md overflow-hidden divide-y divide-cleangray-150">
              {products.map(product => (
                <div key={product._id}>
                  <Link to={`/products/${product._id}`}>
                    <div className="flex justify-between bg-white hover:bg-cleangray-50 cursor-pointer">
                      <p className="w-full text-center px-6 py-4 whitespace-nowrap text-sm">
                        <span className="text-cleangray-600 font-medium">{product.name} </span>
                      </p>
                      <p className="w-full text-center px-6 py-4 whitespace-nowrap text-sm">
                        <span className="text-cleangray-600 font-medium">
                          {(parseFloat(product.length) / 100).toFixed().toString().replace(".", ",")}x{(parseFloat(product.thickness) / 100).toFixed(2).toString().replace(".", ",")}
                        </span>
                      </p>
                      <p className="w-full text-center px-6 py-4 whitespace-nowrap text-sm">
                        <span className="text-cleangray-600 font-medium">{(parseFloat(product.width) / 100).toFixed(1).toString().replace(".", ",")} mm</span>
                      </p>
                      <p className="w-full px-6 py-4 whitespace-nowrap text-sm flex justify-center">{getStatusIcon(product.status ? product.status.code : "-1")}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModelListTable;
