import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { PlusIcon, UserAddIcon } from "@heroicons/react/solid";

// Components
import Page from "../../components/Page/Page";
import Pagination from "../../components/Pagination/Pagination";
import TableSearchbar from "../../components/TableSearchbar/TableSearchbar";
import IconButton from "../../components/IconButton/IconButton";
import ClientList from "../../components/ClientList/ClientList";
import { useDispatch } from "react-redux";

// Store
import * as uiActions from "../../store/actions/ui";

const pages = [{ name: "Kunden", href: "/clients", current: true }]; // Breadcrump config

const clients = [
  {
    id: 1,
    company: "Egon Löh GmbH & Co. KG",
    firstname: "Egon",
    lastname: "Löh",
    clientId: "23401",
    location: "Idar-Oberstein",
    type: "Firmenkunde",
    sector: "Goldschmied"
  }
];

function PageMenu(props) {
  const dispatch = useDispatch();

  function handleClick() {
    // dispatch(uiActions.flashMessage("info", "Testnachricht", "Sehr wichtige Zusatzinformation"));
  }

  return (
    <div>
      <div className="flex space-x-3 items-stretch justify-end">
        <TableSearchbar placeholder="Kunden durchsuchen" className="sm:w-60 w-full" />
        <IconButton className="w-12" icon={PlusIcon} onClick={handleClick} />
      </div>
    </div>
  );
}

function Clients(props) {
  return (
    <Page title="Kunden" caption="Kunden" menu={PageMenu} pages={pages}>
      <ClientList data={clients} />
      <Pagination entity="Kunde" />
    </Page>
  );
}

export default Clients;
