import { ChevronRightIcon, ChevronLeftIcon, HomeIcon } from "@heroicons/react/solid";
import { Link, useHistory } from "react-router-dom";

export default function Breadcrump(props) {
  const { pages, isBackBtn } = props;

  const history = useHistory();

  const defaultBreadcrump = (
    <>
      <li>
        <div>
          <Link to="/" className="text-cleangray-250 hover:text-cleangray-400">
            <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
            <span className="sr-only">Dashboard</span>
          </Link>
        </div>
      </li>
      {pages.map(page => (
        <li key={page.name}>
          <div className="flex items-center">
            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-cleangray-300" aria-hidden="true" />
            <Link to={page.href} className="ml-4 text-sm font-medium text-cleangray-400 hover:text-cleangray-600" aria-current={page.current ? "page" : undefined}>
              {page.name}
            </Link>
          </div>
        </li>
      ))}
    </>
  );

  const pathSplitter = useHistory().location.pathname.split("/");
  const backPath = "/" + pathSplitter[1];

  const backBtn = (
    <li>
      <div className="flex items-center">
        <ChevronLeftIcon className="flex-shrink-0 h-5 w-5 text-cleangray-300" aria-hidden="true" />
        <span onClick={() => history.push(backPath)} className="ml-4 text-sm font-medium text-cleangray-400 hover:text-cleangray-600 cursor-pointer">
          Zurück
        </span>
      </div>
    </li>
  );

  return (
    <nav className={`flex ${isBackBtn ? "mb-4" : ""}`} aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">{isBackBtn ? backBtn : defaultBreadcrump}</ol>
    </nav>
  );
}
