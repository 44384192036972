import React from "react";
import { useField } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

function CustomInput(props) {
  // name
  // label
  // placeholder
  // icon
  // type

  const [field, meta] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const invalidStyle = "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500";

  const idleStyle = "border-cleangray-200 placeholder-cleangray-300 focus:outline-none focus:ring-primary-base focus:border-primary-base";

  return (
    <div>
      <label htmlFor={props.name} className="block text-sm font-medium text-cleangray-700 sr-only">
        {props.label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <props.icon className={"h-5 w-5 " + (isInvalid ? "text-red-500" : "text-cleangray-400")} aria-hidden="true" />
        </div>
        <input id={props.name} name={props.name} type={props.type} className={"appearance-none block w-full px-3 pl-10 border sm:text-sm rounded-md disabled:cursor-not-allowed " + (isInvalid ? invalidStyle : idleStyle)} placeholder={props.placeholder} {...props.fields} aria-describedby={isInvalid ? "email-error" : ""} {...field} disabled={props.disabled} />
        {isInvalid && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {isInvalid && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {meta.error}
        </p>
      )}
    </div>
  );
}

export default CustomInput;
