import React from "react";

import LoadingCircle from "../Icons/LodaingCircle";

function IconButton(props) {
  let bg;

  if (props.tranparent) {
    bg = "bg-transparent " + (props.color ? props.color : "text-cleangray-300 hover:text-cleangray-400") + " ";
  } else {
    bg = "text-white bg-primary-base hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-base shadow-sm ";
  }

  return (
    <button onClick={props.onClick} type="button" className={"inline-flex justify-center items-center space-x-4 border border-transparent text-sm leading-4 font-medium rounded-md " + bg + props.className}>
      {props.loading ? <LoadingCircle className={"h-5 w-5 animate-spin " + props.textColor} /> : props.label ? <span>{props.label}</span> : <props.icon className="h-5 w-5" aria-hidden="true" />}
    </button>
  );
}

export default IconButton;
