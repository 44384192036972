import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import Page from "../../components/Page/Page";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { PencilIcon, CheckCircleIcon, QuestionMarkCircleIcon, ExclamationIcon, ExclamationCircleIcon, CheckIcon } from "@heroicons/react/solid";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Store
import * as uiActions from "../../store/actions/ui";

// Components
import FormControl from "../../components/FormUI/FormControl";
import IconButton from "../../components/IconButton/IconButton";

function ProductDetail(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [product, setProduct] = useState();
  const [formValues, setFormValues] = useState();
  const [statusTypes, setStatusTypes] = useState();
  const [error, setError] = useState(false);

  const id = useParams().id;
  const userToken = useSelector(state => state.auth.user.token);

  const dispatch = useDispatch();
  const history = useHistory();

  // Product Data
  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const productResponse = await axios.post(`/products/${id}`, { token: userToken }, { cancelToken: fetchRequest.token });
        setProduct(productResponse.data);

        const statusResponse = await axios.post(`/getStatusTypes`, { token: userToken }, { cancelToken: fetchRequest.token });
        setStatusTypes(statusResponse.data);

        // get product categories and tags

        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
          setIsLoading(false);
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  // const initialValues = {
  //   status: productResponse.data.status,
  //   length: parseInt(productResponse.data.length) / 100,
  //   thickness: parseInt(productResponse.data.thickness) / 100,
  //   width: parseInt(productResponse.data.width) / 100,
  //   price: parseInt(productResponse.data.price.amount) / 100,
  //   description: productResponse.data.decription // #############################
  // };

  const initialValues = {
    status: "",
    length: "",
    thickness: "",
    width: "",
    price: "",
    description: ""
  };

  const validationSchema = Yup.object({
    status: Yup.string().typeError("Keine gültige Zeichen"),
    length: Yup.number().typeError("Keine gültige Zahl").positive("Bitte geben Sie die Breite ein").required("Pflichtfeld"),
    thickness: Yup.number().typeError("Keine gültige Zahl").positive("Bitte geben Sie die Stärke ein").required("Pflichtfeld"),
    width: Yup.number().typeError("Keine gültige Zahl").positive("Bitte geben Sie den Bedarf ein").required("Pflichtfeld"),
    price: Yup.number().typeError("Keine gültige Zahl").positive("Bitte geben Sie den Listenpreis ein").required("Pflichtfeld"),
    description: Yup.string().typeError("Keine gültige Zeichen")
  });

  function onSubmit(values) {
    setIsSaving(true);

    const refactoredValues = {
      status: values.status,
      length: parseInt(Math.ceil(values.length * 100)) + "",
      thickness: parseInt(Math.ceil(values.thickness * 100)) + "",
      width: parseInt(Math.ceil(values.width * 100)) + "",
      price: parseInt(Math.ceil(values.price * 100)) + "",
      description: values.description
    };

    saveData(refactoredValues);
  }

  async function saveData(data) {
    try {
      const productResponse = await axios.patch(`/products/${id}`, { data: data, token: userToken });

      setIsSaving(false);
      dispatch(uiActions.flashMessage("success", "Speichern erfolgreich"));

      history.push(`/products/${id}`);
    } catch (e) {
      console.log("There was an problem");
      setIsSaving(false);
      dispatch(uiActions.flashMessage("alert", "Speichern Fehlgeschlagen", "Bitte überprüfen Sie Ihre Verbindung"));
    }
  }

  useEffect(() => {
    if (product) {
      setFormValues({
        status: product.status ? product.status._id : "",
        length: parseInt(product.length) / 100,
        thickness: parseInt(product.thickness) / 100,
        width: parseInt(product.width) / 100,
        price: parseInt(product.price.amount) / 100,
        description: product.description
      });
    }
  }, [product]);

  return (
    <Page title={isLoading ? "..." : product.name} caption menu={""} pages={[]} isBackBtn>
      {isLoading ? (
        <h2>Loading...</h2>
      ) : (
        <Formik initialValues={formValues || initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnMount enableReinitialize>
          {formik => (
            <Form>
              <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8">
                <div className="sm:col-span-4 lg:col-span-5">
                  <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                    <img src={axios.defaults.baseURL + (error ? "/images/error.jpg" : product.imgUrl)} alt={product.name} className="object-center object-cover" onError={() => setError(true)} />
                  </div>
                </div>
                <div className="sm:col-span-8 lg:col-span-7">
                  <h2 className="text-2xl font-extrabold text-cleangray-600 sm:pr-12 text-center flex items-center justify-center bg-white p-4 shadow-md rounded-md relative mb-4">
                    {product.name}
                    <IconButton className="absolute right-8 hidden sm:block" tranparent icon={CheckIcon} onClick={() => formik.submitForm()} label={"Speichern"} loading={isSaving} />
                  </h2>

                  <div className="grid grid-cols-3 gap-6 mb-4">
                    <div className="col-span-3">
                      <FormControl control="select" label="Status" name="status" options={[{ _id: "", title: "Noch nicht geprüft" }, ...statusTypes]} />
                    </div>
                    <div className="grid col-span-3 grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-1">
                        <FormControl control="number" label="Materialbreite" name="length" after="mm" />
                      </div>
                      <div className="col-span-3 sm:col-span-1">
                        <FormControl control="number" label="Materialstärke" name="thickness" after="mm" />
                      </div>
                      <div className="col-span-3 sm:col-span-1">
                        <FormControl control="number" label="Materialbedarf" name="width" after="mm" />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <FormControl control="number" label="Listenpreis" name="price" after="€" />
                    </div>
                    <div className="col-span-3">
                      <FormControl control="textarea" label="Bemerkungen" name="description" />
                    </div>
                  </div>

                  <IconButton className="block sm:hidden w-full p-4" icon={CheckIcon} onClick={() => formik.submitForm()} label={"Speichern"} loading={isSaving} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Page>
  );
}

export default ProductDetail;
