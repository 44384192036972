import React from "react";

function LoadingCircle(props) {
  const title = props.title || "l circle";

  return (
    <svg className={props.className} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill="#888888">
        <path d="M16,32A16,16,0,1,1,32,16,16.019,16.019,0,0,1,16,32ZM16,4A12,12,0,1,0,28,16,12.013,12.013,0,0,0,16,4Z" fill="#888888" opacity="0.4" />
        <path d="M32,16H28A12.013,12.013,0,0,0,16,4V0A16.019,16.019,0,0,1,32,16Z" />
      </g>
    </svg>
  );
}

export default LoadingCircle;
