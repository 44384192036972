import { LOGIN, LOGOUT } from "../actions/auth";

const initialState = {
  loggedIn: Boolean(localStorage.getItem("AjwToken")),
  user: {
    token: localStorage.getItem("AjwToken"),
    firstname: localStorage.getItem("AjwFirstName"),
    lastname: localStorage.getItem("AjwLastName"),
    avatar: localStorage.getItem("AjwAvatar")
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: true,
        user: action.user
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false
      };
  }

  return state;
};
