import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AtSymbolIcon, KeyIcon, LockClosedIcon, LockOpenIcon } from "@heroicons/react/solid";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import Logo from "../../assets/ajw-logo-dark.svg";
import { ReactComponent as LoadingSpinnerCircle } from "../../assets/loading-spinner-circle.svg";

// Store
import * as authActions from "../../store/actions/auth";
import * as uiActions from "../../store/actions/ui";

// Components
import Page from "../../components/Page/Page";
import CustomInput from "../../components/CustomInput/CustomInput";

export default function Login() {
  const [isCompanyNetwork, setIsCompanyNetwork] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  // Check if user is within company network
  useEffect(() => {
    const fetchRequest = Axios.CancelToken.source();
    let requestAborted = false;

    async function checkConnection() {
      try {
        const response = await Axios.get(`/checkConnection`);
        if (response.data) {
          setIsLoading(false);
          setIsCompanyNetwork(true);
        }
      } catch (e) {
        if (!requestAborted) {
          console.log("No Connection to Server");
          setIsLoading(false);
        }
      }
    }
    checkConnection();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  async function handleSubmit(values) {
    try {
      const response = await Axios.post("/login", { email: values.email, password: values.password });
      if (response.data) {
        dispatch(authActions.login(response.data));
        dispatch(uiActions.flashMessage("success", "Anmeldung erfolgreich"));
      } else {
        console.log("Invalid login");
        dispatch(uiActions.flashMessage("alert", "Anmeldung fehlgeschlagen", "Bitte überprüfen Sie Ihre Eingaben"));
      }
    } catch (e) {
      console.log(e);
    }
  }

  const initialValues = {
    email: "",
    password: ""
  };

  return (
    <Page title="Login" isLogin>
      <Redirect to="/" />
      <div className="min-h-screen bg-cleangray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-16 w-auto" src={Logo} alt="AJW" />
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-md sm:px-10">
            <Formik
              initialValues={initialValues}
              initialErrors={["not touched"]}
              validateOnBlur={true}
              validationSchema={Yup.object({
                email: Yup.string().email("Keine gültige Email").required("Bitte geben Sie Ihre Email ein"),
                password: Yup.string().required("Bitte geben Sie Ihr Passwort ein")
              })}
              onSubmit={(values, setSubmitting, resetForm) => {
                handleSubmit(values);
              }}>
              {props => (
                <Form className="space-y-5">
                  {/* Email */}
                  <CustomInput name="email" label="Email" placeholder="mitarbeiter@ajw-schmuck.de" icon={AtSymbolIcon} type="email" fields={{ autoComplete: "off" }} disabled={!isCompanyNetwork} />
                  {/* Password */}
                  <CustomInput name="password" label="Passwort" placeholder="Passwort" icon={KeyIcon} type="password" fields={{ autoComplete: "current-password" }} disabled={!isCompanyNetwork} />
                  <div>
                    <button disabled={!isCompanyNetwork || isLoading} type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-base hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-base disabled:opacity-50 disabled:cursor-not-allowed">
                      Anmelden
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className={"w-full flex justify-center space-x-2 py-4"}>
          {isLoading ? (
            <div className="flex justify-center items-center space-x-2">
              <p className="text-cleangray-400">Überprüfe Netzwerk</p>
              <div className="h-5 w-5">
                <LoadingSpinnerCircle />
              </div>
            </div>
          ) : isCompanyNetwork ? (
            <>
              <LockClosedIcon className="h-5 w-5 text-green-500" />
              <p className="text-green-500">Firmennetzwerk</p>
            </>
          ) : (
            <>
              <LockOpenIcon className="h-5 w-5 text-red-500" />
              <p className="text-red-500">Nicht autorisiertes Netzwerk</p>
            </>
          )}
        </div>
      </div>
    </Page>
  );
}
