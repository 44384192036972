import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import Page from "../../components/Page/Page";
import { useSelector } from "react-redux";
import axios from "axios";
import { PencilIcon, CheckCircleIcon, QuestionMarkCircleIcon, ExclamationIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

// Components
import IconButton from "../../components/IconButton/IconButton";

function ProductDetail(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState();
  const [error, setError] = useState(false);
  const [status, setStatus] = useState();

  const id = useParams().id;
  const userToken = useSelector(state => state.auth.user.token);

  useEffect(() => {
    const fetchRequest = axios.CancelToken.source();
    let requestAborted = false;

    async function fetchData() {
      try {
        const response = await axios.post(`/products/${id}`, { token: userToken }, { cancelToken: fetchRequest.token });
        setProduct(response.data);
        setIsLoading(false);
      } catch (e) {
        if (!requestAborted) {
          console.log("There was an problem");
        }
      }
    }
    fetchData();

    return () => {
      requestAborted = true;
      fetchRequest.cancel();
    };
  }, []);

  useEffect(() => {
    if (!product) return;

    if (!product.status) {
      setStatus({
        color: "bg-blue-500",
        icon: <QuestionMarkCircleIcon className="w-5" />
      });
    } else {
      switch (product.status.code) {
        case "0":
          setStatus({
            color: "bg-green-500",
            icon: <CheckCircleIcon className="w-5" />
          });
          break;
        case "1":
          setStatus({
            color: "bg-yellow-500",
            icon: <ExclamationIcon className="w-5" />
          });
          break;
        case "2":
          setStatus({
            color: "bg-red-500",
            icon: <ExclamationCircleIcon className="w-5" />
          });
          break;
      }
    }
  }, [product]);

  const history = useHistory();

  function handleEditButton() {
    history.push(`/products/${id}/edit`);
  }

  return (
    <Page title={isLoading ? "..." : product.name} caption menu={""} pages={[]} isBackBtn>
      {isLoading ? (
        <h2>Loading...</h2>
      ) : (
        <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8">
          <div className="sm:col-span-4 lg:col-span-5">
            <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
              <img src={axios.defaults.baseURL + (error ? "/images/error.jpg" : product.imgUrl)} alt={product.name} className="object-center object-cover" onError={() => setError(true)} />
            </div>
          </div>
          <div className="sm:col-span-8 lg:col-span-7">
            <h2 className="text-2xl font-extrabold text-cleangray-600 sm:pr-12 text-center flex items-center justify-center bg-white p-4 shadow-md rounded-md relative">
              {product.name}
              <IconButton className="w-12 absolute right-4" tranparent icon={PencilIcon} onClick={handleEditButton} />
            </h2>

            <div className={`text-white flex items-center justify-center space-x-4 p-2 rounded-md my-4 ${status.color}`}>
              {status.icon}
              <span>
                <strong>Status:</strong> {product.status ? product.status.title : "Noch nicht geprüft"}
              </span>
            </div>

            <section aria-labelledby="information-heading" className="">
              <h3 id="information-heading" className="sr-only">
                Produkt Information
              </h3>

              {/* Matirial Information - Desktop */}
              <div className="hidden sm:block shadow rounded-md overflow-hidden mb-4 bg-white text-cleangray-400 divide-y divide-cleangray-150">
                <div className="flex justify-between">
                  <p className="w-full px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">Materialbreite</p>
                  <p className="w-full px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">Materialstärke</p>
                  <p className="w-full px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">Materialbedarf</p>
                </div>
                <div className="flex justify-between bg-white">
                  <p className="w-full text-center px-6 py-4 whitespace-nowrap text-sm">
                    <span className="text-cleangray-600 font-medium">{(parseFloat(product.length) / 100).toFixed().toString().replace(".", ",")} mm</span>
                  </p>
                  <p className="w-full text-center px-6 py-4 whitespace-nowrap text-sm">
                    <span className="text-cleangray-600 font-medium">{(parseFloat(product.thickness) / 100).toFixed(2).toString().replace(".", ",")} mm</span>
                  </p>
                  <p className="w-full text-center px-6 py-4 whitespace-nowrap text-sm">
                    <span className="text-cleangray-600 font-medium">{(parseFloat(product.width) / 100).toFixed(1).toString().replace(".", ",")} mm</span>
                  </p>
                </div>
              </div>

              {/* Matirial Information - Mobile */}
              <div className="md:hidden shadow rounded-md overflow-hidden mb-4 bg-white text-cleangray-400 divide-y divide-cleangray-150">
                <div className="flex justify-between">
                  <p className="w-full px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">Materialbreite</p>
                </div>
                <div className="flex justify-between bg-white">
                  <p className="w-full text-center px-6 py-4 text-sm">
                    <span className="text-cleangray-600 font-medium">{(parseFloat(product.length) / 100).toFixed().toString().replace(".", ",")} mm</span>
                  </p>
                </div>
              </div>

              <div className="md:hidden shadow rounded-md overflow-hidden mb-4 bg-white text-cleangray-400 divide-y divide-cleangray-150">
                <div className="flex justify-between">
                  <p className="w-full px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">Materialstärke</p>
                </div>
                <div className="flex justify-between bg-white">
                  <p className="w-full text-center px-6 py-4 text-sm">
                    <span className="text-cleangray-600 font-medium">{(parseFloat(product.thickness) / 100).toFixed(2).toString().replace(".", ",")} mm</span>
                  </p>
                </div>
              </div>

              <div className="md:hidden shadow rounded-md overflow-hidden mb-4 bg-white text-cleangray-400 divide-y divide-cleangray-150">
                <div className="flex justify-between">
                  <p className="w-full px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">Materialbedarf</p>
                </div>
                <div className="flex justify-between bg-white">
                  <p className="w-full text-center px-6 py-4 text-sm">
                    <span className="text-cleangray-600 font-medium">{(parseFloat(product.width) / 100).toFixed(1).toString().replace(".", ",")} mm</span>
                  </p>
                </div>
              </div>

              {/* List Price */}
              <div className="block shadow rounded-md overflow-hidden mb-4 bg-white text-cleangray-400 divide-y divide-cleangray-150">
                <div className="flex justify-between">
                  <p className="w-full px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">Listenpreis</p>
                </div>
                <div className="flex justify-between bg-white">
                  <p className="w-full text-center px-6 py-4 text-sm">
                    <span className="text-cleangray-600 font-medium">{(parseFloat(product.price.amount) / 100).toFixed(2).toString().replace(".", ",")} €</span>
                  </p>
                </div>
              </div>

              {/* Modell Information */}
              <div className="block shadow rounded-md overflow-hidden mb-8 bg-white text-cleangray-400 divide-y divide-cleangray-150">
                <div className="flex justify-between">
                  <p className="w-full px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">Bemerkungen</p>
                </div>
                <div className="flex justify-between bg-white">
                  <div className={"w-full px-6 py-4 text-sm prose prose-sm" + (product.description ? product.description : "text-center")}>{product.description ? <ReactMarkdown className="prose prose-sm max-w-full mt-4 mb-4" children={product.description} remarkPlugins={[remarkGfm]} /> : "Keine Bemerkung hinterlegt"}</div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </Page>
  );
}

export default ProductDetail;
